
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




































































































































































































































































.product-columns,
[class*='product-columns--'] {
  @include bg-color;

  ::v-deep {
    .price-box__promo {
      position: absolute;
      top: 0;
      display: inline-block;
      width: auto;
      padding: 0.5rem 1rem;
      font-size: 1.3rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      background-color: $c-pink-medium;
      border-radius: 0.2rem;
      transform: translateY(-50%);
    }

    .price-box-inner {
      display: flex;
      align-items: center;
      padding: 1rem 2rem;

      img {
        width: 11rem;
      }

      span {
        padding-left: 3rem;
        color: $white;
        font-family: $ff-alt;
        font-size: 3.6rem;
        font-weight: 900;
        text-transform: capitalize;
      }
    }
  }
}

.product-columns__inner {
  padding-top: $spacing * 2.5;
  padding-bottom: $spacing * 2.5;

  [class*='product-columns--'][class*='--triple'] & {
    display: block;

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  @include mq(m) {
    padding-top: $spacing * 4;
    padding-bottom: $spacing * 4;

    // [class*='product-columns--'][class*='--triple'] & {
    //   padding-bottom: $spacing * 6 !important;
    // }
  }

  @include mq(xl) {
    display: flex;
    padding-top: $spacing * 10;

    [class*='product-columns--'][class*='--reversed'] & {
      flex-direction: row-reverse;
      padding-top: $spacing * 2;
    }

    [class*='product-columns--'][class*='--triple'] & {
      // display: block;
      padding-top: $spacing * 2;
    }
  }

  @include mq(xxl) {
    [class*='product-columns--'][class*='--triple'] & {
      display: block;
    }
  }
}

.product-columns__intro {
  margin-bottom: $spacing * 3;

  [class*='product-columns--'][class*='--triple'] & {
    flex-wrap: wrap;
    width: 100%;
    margin-right: 0;
  }

  @include dark-bgs {
    color: $white;
  }

  @include mq(m) {
    flex-shrink: 0;
    width: col(10);
    margin-bottom: $spacing * 5.5;

    [class*='product-columns--'][class*='--triple'] & {
      margin-right: col(1);
    }
  }

  @include mq(xl) {
    width: col(6);
    margin-right: col(1);

    [class*='product-columns--'][class*='--double'] & {
      width: col(4);
      margin-bottom: 0;
    }

    [class*='product-columns--'][class*='--reversed'] & {
      flex-direction: row-reverse;
      margin-right: 0;
      margin-left: col(1);
    }
  }

  @include mq(xxl) {
    [class*='product-columns--'][class*='--triple'] & {
      display: flex;
      flex-shrink: 0;
      width: 100%;
      max-width: none;
      margin-right: 0;
      margin-bottom: $spacing * 6.5;
      margin-left: 0;
    }
  }
}

.product-columns__intro__title {
  b,
  strong {
    display: block;
  }

  @include mq(l) {
    margin: 0 0 $spacing * 1.5;
  }

  @include mq(xxl) {
    [class*='product-columns--'][class*='--triple'] & {
      //width: col(3);
      margin: 0 col(1) 0 0;
    }
  }
}

.product-columns__subtitle {
  @extend %fw-medium;

  display: block;
  margin-top: $spacing / 4;
  margin-bottom: $spacing;
  font-family: $ff-alt;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.product-columns__intro__block {
  @include mq(xxl) {
    [class*='product-columns--'][class*='--triple'] & {
      width: col(6);
      //margin-left: 22rem;
    }
  }

  @include mq($until: xs) {
    margin-left: 0 !important;
    text-align: center;
  }
}

.product-columns__intro__text {
  @extend %fw-light;
}

.product-columns__intro__consumption {
  display: block;
  margin: $spacing * 2 0 $spacing;

  @include mq(l) {
    margin: $spacing * 4.25 0 $spacing;
  }
}

.product-columns__offers {
  [class*='product-columns--'][class*='--triple'] & {
    flex-direction: column;
    align-items: flex-end;

    @include mq(m) {
      flex-wrap: nowrap;
    }

    @include mq(l) {
      flex-direction: row;
      align-items: flex-start;
      max-width: 120rem;
    }
  }

  @include mq(m) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 65rem;
    margin: 0 auto;
  }

  @include mq(l) {
    max-width: 85rem;
    margin: 0 auto;
  }

  @include mq(xl) {
    flex: 1;

    [class*='product-columns--'][class*='--double'] & {
      justify-content: flex-start;
      width: col(8);
      margin: 0 col(-1) 0 0;
    }

    [class*='product-columns--'][class*='--reversed'] & {
      justify-content: flex-end;
      margin: 0 0 0 col(-1);
    }
  }

  @include mq(xxl) {
    justify-content: space-between;
    width: auto;
    margin: 0;

    [class*='product-columns--'][class*='--triple'] & {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      max-width: none;
      min-height: auto;
    }

    [class*='product-columns--'][class*='--reversed'] & {
      margin: 0;
    }
  }
}

.product-columns__offers__item {
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: $spacing * 3;
  }

  [class*='product-columns--'][class*='--triple'] & {
    width: 300px;

    &:last-child {
      margin-top: $spacing * 3;
      margin-bottom: 2rem;
    }

    @include mq(l) {
      width: 380px;
    }
  }

  @include mq(m) {
    margin: 0;

    [class*='product-columns--'][class*='--triple'] & {
      &:nth-child(2) {
        position: absolute;
        transform: translate(-110%, 44%);
      }
    }
  }

  @include mq(l) {
    [class*='product-columns--'][class*='--triple'] & {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        position: relative;
      }

      &:nth-child(2) {
        transform: none;
      }

      &:nth-child(3) {
        margin-top: 0;
      }

      &:not(:last-child) {
        margin-right: $spacing;
      }
    }
  }

  @include mq(xl) {
    &:not(:last-child) {
      margin-right: $spacing;
    }

    [class*='product-columns--'][class*='--double'] & {
      align-self: flex-start;
      width: calc(50% - #{$spacing});
      max-width: 35rem;
    }

    // [class*='product-columns--'][class*='--triple'] & {
    //   &:nth-child(2) {
    //     transform: translate(-110%, 44%);
    //   }
    // }
  }

  @include mq(xxl) {
    [class*='product-columns--'][class*='--triple'] & {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        position: relative;
      }

      // &:nth-child(1) {
      //   transform: translateY(30%);
      // }

      &:nth-child(2) {
        transform: none;
      }

      &:nth-child(3) {
        margin-top: 0;
        margin-right: 0;
        //transform: translateY(10%);
      }
    }
  }
}

.product-columns__speedlines {
  z-index: -1;
  right: -8rem;
}

.product-columns__stamp {
  margin-bottom: $spacing * 2;

  [class*='product-columns--'][class*='--triple'] & {
    width: 100%;
  }
}
